import getRealParams, { RealParamsItemT } from '@functions/getRealParams.ts';
import removeTransition from '@functions/removeTransition.ts';
import setAsyncState from '@functions/setAsyncState.ts';
import setAsyncTimer from '@functions/setAsyncTimer.ts';

import I, { ItemT } from '../types.ts';

const drawItems: I['drawItems'] = async function ({ addesIds, deletesIds }) {
    const { itemClass, itemStyleProps, parentStyleProps, testMode } = this.props;
    const parent = this.parent.current!;

    const needItems: ItemT[] = [];
    const removeTransAddesItems: string[] = [];

    this.state.items.forEach((item) => {
        const itemNode = this.parent.current!.querySelector(
            `.${itemClass}[data-id="${item._id}"]`,
        ) as HTMLElement;

        if (this.states[item._id] === 1) {
            itemNode.setAttribute('data-show', '');
        }

        if (this.states[item._id] === 0) {
            itemNode.removeAttribute('data-show');
        } else {
            needItems.push(item);
        }

        if (addesIds.includes(item._id)) {
            removeTransAddesItems.push(`.${itemClass}[data-id="${item._id}"]`);
        }

        itemNode.style.order = `${this.indexes[item._id]}`;
    });

    if (removeTransAddesItems.length) {
        removeTransition({ item: removeTransAddesItems.join(', '), isCurrent: true });
    }

    if (itemStyleProps.length || parentStyleProps.length) {
        const elems: { className: string; id: string }[] = needItems.map((item) => ({
            className: `.${itemClass}[data-id="${item._id}"]`,
            id: item._id,
        }));

        const params = getRealParams({
            parent,
            elems,
            classNames: ['_static'],
            isClearStyleParent: true,
            clearStyleElems: [],
            isNotRemove: testMode,
            ...(parentStyleProps.includes('width') ? { width: parent.offsetWidth } : {}),
            ...(parentStyleProps.includes('height') ? { height: parent.offsetHeight } : {}),
        });

        needItems.forEach((item) => {
            const itemParams = params[item._id] as RealParamsItemT;

            if (itemParams) {
                const itemNode = this.parent.current!.querySelector(
                    `.${itemClass}[data-id="${item._id}"]`,
                ) as HTMLElement;

                if (itemStyleProps.includes('height')) {
                    itemNode.style.height = `${itemParams.height}px`;
                }

                if (itemStyleProps.includes('width')) {
                    itemNode.style.width = `${itemParams.width}px`;
                }

                if (itemStyleProps.includes('left') || itemStyleProps.includes('top')) {
                    const left = itemStyleProps.includes('left') ? itemParams.offsetLeft : 0;
                    const top = itemStyleProps.includes('top') ? itemParams.offsetTop : 0;

                    itemNode.style.transform = `translate(${left}px,${top}px)`;
                }
            }
        });

        if (parentStyleProps.includes('width')) {
            parent.style.height = `${params.parent.height}px`;
        }

        if (parentStyleProps.includes('height')) {
            parent.style.width = `${params.parent.width}px`;
        }
    }

    await setAsyncTimer(300);

    const resultItems = [...this.state.items.map((item) => ({ ...item }))];

    deletesIds.forEach((id) => {
        if (this.states[id] === 0) {
            const index = resultItems.findIndex((item) => item._id === id);

            if (index !== -1) {
                resultItems.splice(index, 1);
            }
        }
    });

    await setAsyncState.call(this, { items: resultItems });
};

export default drawItems;
